import React, {useState, useEffect, useRef} from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import styled from "styled-components";
import Img from "gatsby-image";
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { StaticQuery } from "gatsby";

import Breadscrum from "../components/Breadscrum";
import ArticleCta from "../components/ArticleCta";
import PopUp from "../components/PopUp";
import PopUpZaveti from "../components/PopUpZaveti";
import CenovaKalkulacka from "../components/CenovaKalkulacka";
import ArticleWidget from "../components/ArticleWidget";
import ArticleFormular from "../components/ArticleFormular";
import ArticleWidgetZaveti from "../components/ArticleWidgetZaveti";

import Telefon from "../assets/img/telefon.svg";
import GoogleLogo from "../assets/img/googleFullRating.svg";
import FirmyLogo from "../assets/img/firmyFullRating.svg";
import Check from "../assets/img/check.svg";
import Hvezdy from "../assets/img/hodnoceniKremace.svg";

export const query = graphql`
  query($slug: String!) {
    allContentfulAsset {
      nodes {
        file {
          url
          contentType
        }
        fluid(maxWidth: 930, maxHeight: 600) {
          ...GatsbyContentfulFluid_noBase64
        }
      }
    }

    supportImage: file(relativePath: { eq: "podpora.png" }) {
      childImageSharp {
        fixed(width: 150, quality: 100) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    allContentfulBlog(filter: {slug: {eq: $slug }}) {
      edges {
        node {
          id
          heading
          title
          description
          displayHeading
          datum(formatString: "DD. MM. YYYY")
          datumProper: datum
          slug
          perex {
            perex
          }
          obrazek {
            fluid(maxWidth: 930, maxHeight: 600) {
               ...GatsbyContentfulFluid_noBase64
            }
            file {
              url
            }
          }
          autor {
            jmeno
            obrazek {
              fixed(width: 60) {
                 ...GatsbyContentfulFixed_noBase64
              }
              file {
                url
              }
            }
          }
          kategorie {
            nzev
          }
          obsah {
            childContentfulRichText {
              html
            }
            json
          }
          doporuceneClanky {
            id
            slug
            heading
          }
        }
      }
    }
  }
`;


const BlogPostTemplateWrap = styled.div`
  background: #FBFBFA;
  width: 100%;
  padding-top: 80px;
  padding-bottom: 120px;
  position: relative;
  overflow: visible;

  @media (max-width: 900px) {
    padding-top: 30px;
    padding-bottom: 40px;
  }
`;

const BlogPostTemplateInner = styled.section`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 25px;
  display: flex;
  gap: 40px;
  align-items: flex-start;
  min-height: 100vh;
  position: relative;
  
  @media (max-width: 1250px) {
    width: calc(100% - 50px);
    max-width: 720px;
    padding: 0;
    display: block;
  }

  @media (max-width: 900px) {
    margin: 0 auto;
    max-width: 600px;
  }

  .mainContent {
    width: 720px;
    flex-shrink: 0;

    @media (max-width: 1250px) {
      width: 100%;
    }
  }

  .stickyContainer {
    flex: 1;
    position: relative;
    align-self: stretch;

    @media (max-width: 1250px) {
      display: none;
    }
  }

  .stickyPanel {
    position: sticky;
    top: 100px;
    width: 340px;
    background: #FFFFFF;
    padding: 30px;
    box-shadow: 0 9px 20px 0 rgba(0,0,0,0.07);
    border-radius: 5px;
    margin-top: 45px;

    @media (max-width: 1250px) {
      display: none;
    }

    h3 {
      font-family: Visuelt-Medium;
      font-size: 22px;
      color: #000000;
      margin-bottom: 20px;
    }

    p {
      font-family: Visuelt-Regular;
      font-size: 16px;
      color: #4D4D4D;
      line-height: 24px;
      margin-bottom: 20px;
    }

    .support {
      display: flex;
      align-items: center;
      margin-bottom: 25px;

      .rightSupp {
        margin-left: 25px;

        .phone {
          display: flex;
          align-items: center;
          text-decoration: none;

          img {
            height: 20px;
            margin-right: 12px;
            margin-top: -1px;
          }

          span {
            font-family: Visuelt-Medium;
            font-size: 24px;
            color: #000000;
          }
        }

        .kDispozici {
          width: fit-content;
          margin-top: 10px;

          .greenDot {
            background: #49B25D;
            height: 10px;
            width: 10px;
            border-radius: 100%;
            animation: pulse 2s infinite;
            display: inline-block;
            vertical-align: middle;
          }

          span {
            font-family: Visuelt-Medium;
            font-size: 16px;
            color: #48B15C;
            line-height: 24px;
            vertical-align: middle;
            margin-left: 10px;
          }

          @keyframes pulse {
            0% {
              opacity: 1;
            }
            50% {
              opacity: 0.25;
            }
            100% {
              opacity: 1;
            }
          }
        }
      }
    }

    .cta {
      display: block;
      background: #1D3A5B;
      color: white;
      text-align: center;
      padding: 19px 25px;
      border-radius: 3px;
      text-decoration: none;
      font-family: Visuelt-Medium;
      font-size: 18px;
      transition: background 0.2s ease;
      margin-bottom: 30px;

      &:hover {
        background: #081C33;
      }
    }

    hr {
      border: 0;
      margin: 25px 0;
      border-top: 1px solid #D8D8D8;
    }

    .sites {
      display: flex;
      gap: 20px;
      align-items: center;
      margin-top: 5px;

      .site {
        display: flex;
        align-items: center;

        .logo {
          max-width: 160px;
          max-height: 28px;
          width: fit-content;
        }

        .stars {
          height: 20px;
        }
      }
    }

    .overenaFirma {
      display: flex;
      margin-bottom: 15px;

      div {  
        img {
          height: 14px;
          margin-left: 12px;
          margin-top: -2px;
        }

        span {
          font-family: Visuelt-Medium;
          font-size: 14px;
          color: #000000;
        }

        &:first-child {
          img {
            height: 16px;
            margin: 0;
            margin-right: 10px;
            margin-top: -1px;
          }
        }
      }
    }
  }

  .videoWrapperOuter {
    max-width: 500px;
    margin-top: 40px;
    margin-bottom: 40px;

    .videoWrapper {
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      height: 0;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  
  .top {
    margin-bottom: 50px;

    @media (max-width: 900px) {
      margin-bottom: 40px;
    }

  
    h1 {
      font-family: NewKansas;
      font-size: 34px;
      color: #0c1c31;
      line-height: 45px;
      margin-top: 15px;

      @media (max-width: 900px) {
        font-size: 32px;
        margin-top: 10px;
        line-height: 42px;
      }
    }

    .autor {
      margin-top: 15px;

      .autorImg {
        width: 30px !important;
        height: 30px !important;
        vertical-align: text-bottom;
        border-radius: 100%;
        margin: 0;
      }

      .autorJmeno {
        font-family: Visuelt-Medium, Helvetica;
        font-size: 14px;
        color: #0c1c31;
        line-height: 25px;
        vertical-align: text-bottom;
        margin-left: 15px;
      }

      .autorDatum {
        font-family: Visuelt-Medium;
        font-size: 14px;
        color: #7F7F7F;
        line-height: 25px;
        vertical-align: text-bottom;
        margin-left: 20px;
      }
    }

    .mainPhoto {
      width: calc(100%);
      display: block;
      margin-top: 35px;
      margin-bottom: 35px;
      border-radius: 10px;
      max-width: 500px;

      @media (min-width: 900px) {
        margin-top: 60px;
        margin-bottom: 60px;
      }
    }

    h6 {
      font-family: Visuelt-Medium, Helvetica;
      text-transform: uppercase;
      font-size: 14px;
      letter-spacing: 1px;
      margin-top: 40px;
      margin-bottom: 15px;
    }

    ol {
      border-bottom: 1px solid rgb(193, 193, 193);
      padding-bottom: 25px;

      li {
        list-style: none;
        margin: 12px auto;

        a {
          margin: 18px 0;
          font-family: Visuelt-Regular, Helvetica;
          font-size: 16px;
          color: rgba(12, 28, 49, 0.60);
          text-transform: unset;
          text-decoration: underline;
          line-height: 24px;
        }
      }
    }

    p {
      font-family: Visuelt-Regular, Helvetica;
      font-size: 18px;
      color: rgb(12, 28, 49);
      line-height: 30px;
      margin-top: 40px;

      @media (max-width: 900px) {
        margin-top: 35px;
        font-size: 16px;
        line-height: 25px;
      }
    }

    .breadscrum {
      margin: 0; 

      ol {
        padding: 0 !important;
        border: 0 !important;

        a {
          margin: 0;
          font-size: 14px;
          color: #8b8b8b;
          text-decoration: none;
          line-height: inherit !important;
          text-transform: unset !important;
        }

        .mobilni {
          text-transform: unset !important;
        }
      }
    }
  }

  

  .content {
    .gatsby-image-wrapper {
      width: calc(100%);
      display: block;
      max-width: 500px;
      border-radius: 10px;

      div {
        padding-bottom: 71.5161% !important;
      }
    }

    ol {
      margin-left: 23px;

      li {
        p {
          margin: 18px 0;
        }
      }
    }

    p {
      font-family: Visuelt-Regular, Helvetica;
      font-size: 17px;
      color: rgba(0, 0, 0, 0.8);
      line-height: 28px;
      margin: 25px auto;

      @media (max-width: 900px) {
        font-size: 16px;
        line-height: 25px;
      }

      a {
        color: rgba(0, 0, 0, 0.8);
      }

      b {
        font-weight: normal;
        font-family: Visuelt-Bold, Helvetica;
      }
    }

    h1 {
      font-family: Ivar, Helvetica;
      font-size: 30px;

      b {
        font-weight: normal;
        font-family: Ivar, Helvetica;
      }
    }

    h2 {
      margin-top: 65px;
      margin-bottom: 35px;
      font-family: Visuelt-Medium, Helvetica;
      font-size: 30px;
      color: #000000;

      @media (max-width: 900px) {
        margin-top: 45px;
        margin-bottom: 25px;
        font-size: 25px;
      }

      b {
        font-weight: normal;
        font-family: Visuelt-Bold, Helvetica;
      }
    }

    h3 {
      font-family: Visuelt-Medium, Helvetica;
      font-size: 24px;
      color: #000000;
      margin-top: 50px;
      margin-bottom: 35px;

      @media (max-width: 900px) {
        margin-top: 35px;
        margin-bottom: 20px;
        font-size: 22px;
      }

      b {
        font-weight: normal;
        font-family: Visuelt-Bold, Helvetica;
      }
    }

    h4 {
      font-family: Visuelt-Medium, Helvetica;
      font-size: 21px;
      margin-top: 40px;
      margin-bottom: 25px;

      @media (max-width: 900px) {
        margin-top: 30px;
        margin-bottom: 20px;
        font-size: 20px;
      }

      b {
        font-weight: normal;
        font-family: Visuelt-Bold, Helvetica;
      }
    }

    h5 {
      font-family: Visuelt-Medium, Helvetica;
      font-size: 18px;
      margin-top: 35px;
      margin-bottom: 25px;

      @media (max-width: 900px) {
        margin-top: 30px;
        margin-bottom: 20px;
        font-size: 18px;
      }

      b {
        font-weight: normal;
        font-family: Visuelt-Bold, Helvetica;
      }
    }

    ul {
      padding-left: 20px;
      margin: 30px 0;

      li {
        p {
          margin: 5px 0;
        }
      }
    }
  }

  .bot {

    .cta {
      font-family: Visuelt-Medium, Helvetica;
      font-size: 18px;
      color: #0c1c31;
      text-align: right;
      text-decoration: none;
      background: #F3F1EC;
      padding: 20px 0;
      display: block;
      text-align: center;
      border-radius: 3px;
      margin-top: 50px;
      margin-bottom: 40px;
      max-width: 310px;

      @media (min-width: 900px) {
        margin-top: 70px;
      }

      img {
        height: 11px;
        margin-left: 10px;
      }
    }

    div {
      margin-top: 40px;

      @media (min-width: 900px) {
        margin-top: 60px;
      }

      h4 {
        font-family: Visuelt-Bold, Helvetica;
        font-size: 16px;
        color: #0c1c31;
        line-height: 25px;
        text-transform: uppercase;
        margin-bottom: 20px;
      }

      ul {
        li {
          list-style: none;
          margin-bottom: 15px;

          a {
            font-family: Visuelt-Regular, Helvetica;
            font-size: 16px;
            color: #787878;
            line-height: 25px;
          }
        }
      }
    }
  }
`;

const BlogPostTemplate = (props) => {
  const data = props.data.allContentfulBlog.edges[0].node;
  const [haDvojky, setHaDvojky] = useState([]);

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Article",
    "headline": data.heading,
    "image": [
      data.obrazek.file.url,
      ],
    "datePublished": data.datumProper,
    "dateModified": data.datumProper,
    "author": {
      "@type": "Person",
      "name": data.autor.jmeno,
    },
    "publisher": {
      "@type": "Organization",
      "name": "Goodbye.cz",
      "logo": {
        "@type": "ImageObject",
        "url": "https://i.ibb.co/5nW0Cb6/logo-b.png"
      }
    },
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": `https://goodbye.cz/poradna/${data.slug}/`,
    },
  }



  useEffect(() => {
    let h2s = [];

    data.obsah.json.content.forEach(el => {
      if (el.nodeType === "heading-2") {
        h2s.push(el.content[0].value);
      }
    });

    setHaDvojky(h2s);
  }, [data]);




  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const fluid = props.data.allContentfulAsset.nodes.find(n => n.file.url === node.data.target.fields.file["en-US"].url).fluid;
        return (
          <Img alt={data.heading} fluid={fluid} />
        );
      },

      [BLOCKS.EMBEDDED_ENTRY]: node => {
        return (
          <div className="videoWrapperOuter">
            <div className="videoWrapper" dangerouslySetInnerHTML={{__html: node.data.target.fields.code["en-US"]}} />
          </div>
        );
      },

      [BLOCKS.HEADING_2]: node => {
        return (
          <h2 id={node.content[0].value}>{node.content[0].value}</h2>
        );
      },

      [INLINES.ENTRY_HYPERLINK]: node => {
        return (
          <Link to={`/poradna/${node.data.target.fields.slug["en-US"]}/`}>{node.content[0].value}</Link>
        );
      },

      [INLINES.HYPERLINK]: node => {
        return (
          <Link to={node.data.uri}>{node.content[0].value}</Link>
        );
      },

      [BLOCKS.HR]: node => {

        if (data.slug === "prehled-cen-pohrbu" || data.slug === "pohrebne") {
          return (
            <CenovaKalkulacka />
          );
        }

        else if (data.slug === "umrti-spoluvlastnika-nemovitosti" || data.slug === "nemovitost-po-umrti") {
          return (
            <ArticleFormular />
          );
        }

        else if (data.slug === "jak-napsat-zavet" || data.slug === "vydedeni-podle-dedickeho-prava") {
          return (
            <ArticleWidgetZaveti />
          );
        }

        else if (data.slug === "umrti-miminka") {
          return (
            <ArticleCta ctaText="Získejte nezávaznou nabídku" ctaLink="/pohreb-miminka/" text={<p>Postaráme se o důstojné rozloučení s vaším miminkem tak, jak si budete přát. Společně naplánujeme nejen pohřeb, ale pomůžeme vám také najít nejlepší cestu, jak si na vaše dítě uchovat památku.</p>} heading="Pohřební služba pro děti - Goodbye" />
          );
        }

        else if (data.slug === "predplacene-pohrby") {
          return (
            <ArticleCta ctaText="Získejte nezávaznou nabídku" ctaLink="/predplaceny-pohreb/" text={<p>Předplaťte si s námi pohřeb jednoduše online nebo přes telefon. Plánujte dopředu a ušetřete svým nejbližším starosti a stres z plánování pohřbu. Za pár minut máte hotovo, spolehlivě a za transparentní cenu.</p>} heading="Předplacený pohřeb - Goodbye" />
          );
        }

        else {
          return (
            <ArticleWidget />
          );
        }
        
      }
    },
  };

  let pocitadlo = 0;

  // should i display the top cta?

  let zadnaPodpora = true;

  let allowedSlugs = [
    "jak-napsat-zavet",
    "umrti-spoluvlastnika-nemovitosti",
    "dedicke-rizeni",
    "prehled-cen-pohrbu",
    "umrti-blizke-osoby-pruvodce",
    "predplacene-pohrby",
    "trend-predplaceni-pohrbu",
    "jak-naplanovat-pohreb",
    "pohrebni-hudba",
    "smutecni-kytice-pohreb",
    "pracovni-volno-pohreb",
    "kremacni-popel",
    "pruvodce-tvorbou-smutecniho-oznameni",
    "pohrebni-hostina",
    "umrti-miminka",
    "smutecni-rec-rady-tipy",
  ];

  if (allowedSlugs.includes(data.slug)) {
    zadnaPodpora = false;
  }


  return (
    <Layout V2 zadnaPodpora={zadnaPodpora}>
      
      <SEO
        title={data.title}
        description={data.description}
        customTitle={true}
        image={`https:${data.obrazek.file.url}`}
        article={true}
      />

      

      <script type="application/ld+json" dangerouslySetInnerHTML={{__html: JSON.stringify(structuredData)}} />
      <BlogPostTemplateWrap>
        <BlogPostTemplateInner>
          <div className="mainContent">
            <div className="top">
              <Breadscrum
                visible={true}
                stranky={[
                  {name: "Domů", url: "/"},
                  {name: "Poradna", url: "/poradna/"},
                  {name: data.displayHeading, url: `/poradna/${data.slug}/`},
                ]}
              />
              <h1>{data.heading}</h1>
              <div className="autor">
                <Img className="autorImg" alt={data.autor.jmeno} fixed={data.autor.obrazek.fixed} />
                <span className="autorJmeno">{data.autor.jmeno}</span>
                <span className="autorDatum">{data.datum}</span>
              </div>
              <p>{data.perex.perex}</p>

              <Img className="mainPhoto" alt={data.heading} fluid={data.obrazek.fluid} />

              <h6>Obsah</h6>
              <ol>
                {haDvojky.map(el => {
                  pocitadlo++;
                  return (
                    <li key={pocitadlo}><AnchorLink offset='30' href={`#${el}`}>{el}</AnchorLink></li>
                  );
                })}
              </ol>
            </div>

            <div className="content">
              {documentToReactComponents(data.obsah.json, options)}
            </div>

            <div className="bot">
              {data.kategorie.nzev === "Právo a dědictví" ? <>
                
                <ArticleCta
                  heading="Potřebujete pomoci sepsat závěť?"
                  text={<p>Vytvořte si vlastní závěť do 15 minut přes naší aplikaci. Dokument vám potom zkontrolují experti na dědické právo. To vše za <span className="nowrap">2 190 Kč</span>. <Link to="/zaveti/">Zjistit více</Link></p>}
                  ctaText="Začít zdarma"
                  ctaLink="/zaveti/"
                />

              </> : null}


              {data.kategorie.nzev === "Úmrtí a pohřeb" ? <>

                <ArticleCta
                  heading="Potřebujete pomoci sjednat pohřeb?"
                  text={<p>Pomůžeme vám naplánovat jedinečné rozloučení pro vašeho blízkého (<Link to="/pohrebni-sluzba-praha/">Praha</Link>, <Link to="/pohrebni-sluzba-brno/">Brno</Link> a <Link to="/pobocky/">další</Link>). Pohřeb s obřadem, kremace bez obřadu nebo i netradiční pohřeb. To vše za jednu transparentní cenu od <span className="nowrap">15 990 Kč</span>. <Link to="/pohrebni-sluzba/">Zjistit více</Link></p>}
                  ctaText="Začít"
                  ctaLink="/pohrebni-sluzba/"
                />

                <PopUp />
             
              </> : null}

              {data.doporuceneClanky
                ?
                <div>
                  <h4>Další články</h4>
                  <ul>
                    {data.doporuceneClanky.map((o) =>
                      <li key={o.id}>
                        <Link to={`/poradna/${o.slug}/`}>{o.heading}</Link>
                      </li>
                    )}
                  </ul>
                </div>
                :
                null
              }
            </div>
          </div>

          {/* Sticky Panel */}
          <div className="stickyContainer">
            {(data.kategorie.nzev === "Úmrtí a pohřeb" || data.kategorie.nzev === "Další články") && (
              <div className="stickyPanel">
                <h3>Pomůžeme vám naplánovat důstojné rozloučení</h3>
                <p>Chápeme, že smrt blízkého je situace, kterou neplánujete. Zavolejte nám a my jsme připraveni vaši situaci okamžitě řešit.</p>
                
                <div className="support">
                  <Img className="obrazek" alt="Naše podpora" fixed={props.data.supportImage.childImageSharp.fixed} />
                  <div className="rightSupp">
                    <a href="tel:315558136" className="phone">
                      <img src={Telefon} alt="" />
                      <span>315 558 136</span>
                    </a>
                    <div className="kDispozici">
                      <div className="greenDot"></div>
                      <span>K dispozici 24/7</span>
                    </div>
                  </div>
                </div>

                <Link to="/pohrebni-sluzba/" className="cta">Začít plánovat pohřeb</Link>

                <hr />

                <div className="overenaFirma">
                  <div>
                    <img src={Check} alt="" />
                    <span>Ověřeno zákazníky</span>
                  </div>
                </div>

                <div className="sites">
                  <div className="site">
                    <img className="logo" src={GoogleLogo} alt="Google Logo" />
                  </div>
                  <div className="site">
                    <img className="logo" src={FirmyLogo} alt="Firmy.cz Logo" />
                  </div>
                </div>
              </div>
            )}

            {data.kategorie.nzev === "Právo a dědictví" && (
              <div className="stickyPanel">
                <h3>Pomůžeme vám sepsat závěť online</h3>
                <p>Vytvořte si vlastní závěť do 15 minut přes naší aplikaci. Dokument vám potom zkontrolují experti na dědické právo.</p>
                
                <div className="support">
                  <Img className="obrazek" alt="Naše podpora" fixed={props.data.supportImage.childImageSharp.fixed} />
                  <div className="rightSupp">
                    <a href="tel:315558136" className="phone">
                      <img src={Telefon} alt="" />
                      <span>315 558 136</span>
                    </a>
                    <div className="kDispozici">
                      <div className="greenDot"></div>
                      <span>K dispozici 24/7</span>
                    </div>
                  </div>
                </div>

                <Link to="/zaveti/" className="cta">Začít zdarma</Link>

                <hr />

                <div className="overenaFirma">
                  <div>
                    <img src={Check} alt="" />
                    <span>Ověřeno zákazníky</span>
                  </div>
                </div>

                <div className="sites">
                  <div className="site">
                    <img className="logo" src={GoogleLogo} alt="Google Logo" />
                  </div>
                  <div className="site">
                    <img className="logo" src={FirmyLogo} alt="Firmy.cz Logo" />
                  </div>
                </div>
              </div>
            )}
          </div>
        </BlogPostTemplateInner>
      </BlogPostTemplateWrap>
    </Layout>
  );
}

export default BlogPostTemplate;
